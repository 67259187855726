import React, { createContext, ReactNode, useContext } from 'react'
import { slugs } from '@/constants/slugs'

interface GiveawayProjectProvider {
  children: ReactNode
  theatricalSlug: string
}

interface Value {
  theatricalSlug: string
  projectName: string
}

const projectName = {
  [slugs.homestead]: 'Homestead',
  [slugs.braveTheDark]: 'Brave the Dark',
}

const GiveawayProjectContext = createContext<Value>({ theatricalSlug: '', projectName: '' })

export const useGiveawayProjectContext = () => useContext(GiveawayProjectContext)

export const GiveawayProjectProvider: React.FC<GiveawayProjectProvider> = ({ children, theatricalSlug }) => {
  return (
    <GiveawayProjectContext.Provider
      value={{
        theatricalSlug,
        projectName: projectName?.[theatricalSlug] ?? '',
      }}
    >
      {children}
    </GiveawayProjectContext.Provider>
  )
}
